import axios from "axios"

 //const API_URL='http://localhost:5003/'
   // const API_URL='https://mobilenotary-api.alcax.com/'
   const API_URL = "https://api.mobilenotaryx.ai/";
console.log('token=====>',axios.defaults.headers)


export const userSignup = (payload)=>{
    return axios.post(API_URL+'users/signup',payload)
}

export const userSignin = (payload)=>{
    return axios.post(API_URL+'users/signin',payload)
}
export const userForgetPass = (payload)=>{
    return axios.post(API_URL+'users/forget-pass',payload)
}
export const verifyForgetPass = (payload)=>{
    return axios.post(API_URL+'users/verify-forget-pass',payload)
}



export const socialSignin = (payload)=>{
    return axios.post(API_URL+'users/social-signin',payload)
}

export const getAllUsers = ()=>{
    return axios.get(API_URL+ 'users/get-users')
}

export const getStates = ()=>{
    return axios.get(API_URL+'users/get-states')
}
export const getCities = ()=>{
    return axios.get(API_URL+'users/get-cities')
}

export const getFlatprices = ()=>{
    return axios.get(API_URL+'users/get-flat-prices')
}
export const getServices = ()=>{
    return axios.get(API_URL+ 'users/get-services')
}
export const getService = (id)=>{
    return axios.get(API_URL+ `users/get-service/${id}`)
}

export const createJob = (payload)=>{
    return axios.post(API_URL +'jobs/create',payload)
}
export const guestUserCreate =(payload)=>{
    return axios.post(API_URL+'jobs/guset-user-create',payload)
}

export const getFaqsList = ()=>{
    return axios.get(API_URL + 'users/get-faqs')
}

export const getProfile = ()=>{
    return axios.get(API_URL + 'users/get-profile')
}

export const updateProfile= (payload)=>{
    return axios.put(API_URL+ 'users/edit-profile',payload)
}
export const userResetPassword= (payload)=>{
    return axios.put(API_URL+ 'users/reset-pass',payload)
}

export const updateProfileImage = (payload)=>{
    return axios.put(API_URL+'users/edit-profile-image',payload)
}

export const changePassword = (payload)=>{
    return axios.put(API_URL+'users/change-pass',payload)
}

export const getMyJobs = ()=>{
    return axios.get(API_URL+'users/get-myJobs')
}

export const getJobDetails =(id)=>{
    return axios.get(API_URL+`users/get-job-details/${id}`)
}

export const getOrCreateChatRoom =(payload)=>{
    return axios.post(API_URL+'chats/getOrCreateSession',payload)
}

export const getChatOfUser = ()=>{
    return axios.get(API_URL+'chats/getChats')
}

export const getMessagesOfChat =(chatId, page)=>{
    return axios.get(API_URL+`chats/getChatMessages/${chatId}?page=${page}`)
}

export const addNewMessage =(payload)=>{
    return axios.post(API_URL+'chats/newMessage',payload)
}

export const addPaymentMethod =(payload)=>{
    return axios.post(API_URL+'users/add-card',payload)
}


export const editAddedCard =(payload,cardId)=>{
    return axios.put(API_URL+`users/edit-card/${cardId}`,payload)
}
export const deleteAddedCard =(cardId)=>{
    return axios.delete(API_URL+`users/delete-card/${cardId}`)
}

export const getAddedCards =()=>{
    return axios.get(API_URL+'users/get-cards')
}

export const getStaticContent = (type)=>{
    return axios.get(API_URL+ `users/static-content?type=${type}`)
}

export const getLanguages = ()=>{
    return axios.get(API_URL+`users/get-languages`)
}
export const createContactFrom = (payload)=>{
    return axios.post(API_URL+`contactForm`,payload)
}
export const getChoosePreferred = ()=>{
    return axios.get(API_URL+`jobs/user-job-accept-notary`)
}